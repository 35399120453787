<template>
  <el-container style="height: 100%">
    <el-header>Header</el-header>
    <el-main style="height: calc(100% - 120px)">
      <el-row :gutter="20">
        <el-col :span="6"
          ><el-button type="primary" @click="dorp"
            >跳转图片转pdf</el-button
          ></el-col
        >
        <el-col :span="6"
          ><el-button type="primary" @click="translateExcel"
            >跳转excel翻译</el-button
          ></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="24"
          ><el-input
            v-model="input"
            placeholder="请输入需要获取视频内容"
            style="width: 60%"
          ></el-input>
          <el-button type="primary" @click="getUrl">获取</el-button></el-col
        >
      </el-row>
      <div v-if="list.length">
        <div v-for="(item, index) in list" :key="item">
          <div>
            <vue3videoPlay
              class="video-player vjs-custom-skin"
              :ref="'videoPlayer' + index"
              :playsinline="true"
              :options="playerOptions"
              v-bind="options"
              :src="item"
            >
            </vue3videoPlay>
          </div>
          <div class="butn">
            <el-button type="primary" @click="down(item)">下载</el-button>
          </div>
        </div>

        <div v-if="percentage">
          <el-progress :percentage="percentage" />
        </div>
      </div>
    </el-main>
    <el-footer>
      <a
        href="https://beian.miit.gov.cn/"
        rel="external nofollow"
        target="_blank"
        >粤ICP备2024175698号</a
      >
    </el-footer>
  </el-container>
</template>

<script>
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css
import axios from "axios";
import { ElMessage } from "element-plus";
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    vue3videoPlay,
  },
  data() {
    return {
      input: "",
      list: [],
      options: {
        width: "400px", //播放器高度
        height: "400px", //播放器高度
        color: "#409eff", //主题色
        title: "", //视频名称
        muted: false, //静音
        webFullScreen: false,
        speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false, //关灯模式
        volume: 0.3, //默认音量大小
        control: true, //是否显示控制器
      },
      percentage: null,
    };
  },
  methods: {
    getUrl() {
      const url = process.env.VUE_APP_API_URL;
      console.log(url, "------url");
      axios
        .get(`${url}/api/url`, {
          params: {
            url: this.input,
          },
          method: "get",
        })
        .then((res) => {
          if (res) {
            this.list = res.data;
          }
          console.log(res);
        });
    },
    down(fileUrl) {
      this.percentage = 0;
      axios
        .post(
          fileUrl,
          {},
          {
            responseType: "blob",
            timeout: 300000,
            onDownloadProgress: (ProgressEvent) => {
              const load = ProgressEvent.loaded;
              const total = ProgressEvent.total;
              const progress = (load / total) * 100;
              this.percentage = Math.ceil(progress);
              if (progress == 100) {
                this.percentage = null;
                ElMessage({
                  message: "文件下载完成",
                  type: "success",
                  duration: 2000,
                });
                //下载完成
                //   _this.fileDown.loadDialogStatus = false
              }
            },
          }
        )
        .then((response) => {
          let url = fileUrl;
          let startIndex = url.lastIndexOf("/") + 1; // 获取"/"后面的索引
          let endIndex = url.lastIndexOf(".mp4"); // 获取".mp4"的索引

          let content = url.substring(startIndex, endIndex);
          const blob = new Blob([response.data]);
          const a = document.createElement("a");
          // 创建下载的链接
          a.href = window.URL.createObjectURL(blob);
          a.download = decodeURIComponent(content) + ".mp4";
          a.style.display = "none";
          //a标签追加元素到body内
          document.body.appendChild(a);
          //模拟点击下载
          a.click();
          // 下载完成移除元素
          document.body.removeChild(a);
          // 释放掉blob对象
          window.URL.revokeObjectURL(a.href);
        })
        .catch(console.error);
    },
    dorp() {
      window.open("https://www.camscanner.com/pictopdf", "_blank");
    },
    translateExcel() {
      window.open("https://app.xunjiepdf.com/excelfanyi", "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.el-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.top {
  display: flex;
}
.butn {
  display: flex;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
